#experience{
    margin-bottom:100px;
}

#experience ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

#experience li{
    display: flex;
    flex-direction: row;
    border: var(--border);
    border-radius: 20px;
    margin-bottom: 30px ;
    padding: 20px; 
    transition: transform 0.5s ease-in-out, box-shadow 0.3s ease;
}

#experience li:hover {
    transform: scale(1.05) translateY(-1px);
    box-shadow: var(--boxshadow);
  }

#experience p{
    margin-bottom:0;
}

.duration {
    width: 50%;
}

.exp-details{
    width:50%;
}

/* @media (max-width: 768px) {
    #experience li {
        flex-direction: column;
    }

    .duration,
    .exp-details {
        width: 100%;
    }

    .duration {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    #experience li {
        padding: 10px;
    }

    .duration,
    .exp-details {
        font-size: 14px;
    }
} */
