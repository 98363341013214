
  .sidebar {
    width: 30%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--bg);
    transition: background-color 1s ease-in-out;
  }

  header {
    font-size: 24px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50%;
  }

  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    margin-bottom: 1rem;
  }
  
  nav ul li a {
    color: var(--text);
    font-size: 1rem;
    transition: all 1s ease-in-out; 
  }
  
  nav ul li a.active {
    font-size: 3rem;
    /* color: yellow; */
    transition: all 1s ease-in-out; 
  }

  .header-icon {
    filter: var(--icon-fill);
    }

    .socials{
    padding-bottom: 20px;
    bottom: 0px;
    position: absolute;
    }


  /* @media (min-width: 600px) {
    .sidebar {
      width: 40%;
      flex-direction: row;
    }
    
    header {
      width: 50%;
      text-align: left;
    }
    
    nav ul {
      text-align: left;
    }
  }
  
  @media (min-width: 768px) {
    .sidebar {
      width: 30%;
    }
    
    header {
      width: 50%;
    }
    
    nav ul li a {
      font-size: 1.2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .sidebar {
      width: 25%;
    }
    
    header {
      width: 50%;
    }
    
    nav ul li a {
      font-size: 1.4rem;
    }
  } */