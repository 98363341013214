 .project-item {
    display: flex;
    flex-direction: row;
    border: var(--border);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 30px; 
    transition: transform 0.5s ease-in-out, box-shadow 0.3s ease;
    transition: border 1s ease-in-out; 
}

h3{
    margin: 0;
}

.project-details {
    width: 500%;
  }

.project-details p {
padding-right: 10px;
}

.project-image {
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.project-image img{
    width: 100%;  
    height: 100%;
    border-radius: 20px;
    align-self: center;
} 

.project-item:hover {
    transform: scale(1.05) translateY(-1px);
    box-shadow: var(--boxshadow);
    transition: all 1s ease-in-out; 
  }

.icon {
margin-right: 10px;
height: 30px;
}

.filter-icon {
width: 70px;
filter: var(--icon-fill);
}

.project-repo{
    display: flex;
    flex-direction: row;
}

.project-repo-item{
    margin: 10px 10px 0px 0px;
    border: var(--border);
    border-radius: 20px;
    padding:10px;
}

.project-repo-item a{
    text-decoration: none;
    color: var(--text);
}

/* Media Queries */
/* @media (min-width: 600px) {
    .project-item {
      flex-direction: row;
    }
  
    .project-details {
      width: 70%;
    }
  
    .project-image {
      width: 30%;
    }
  }
  
  @media (min-width: 768px) {
    .project-item {
      flex-direction: row;
    }
  
    .project-details {
      width: 60%;
    }
  
    .project-image {
      width: 40%;
    }
  }
  
  @media (min-width: 1024px) {
    .project-item {
      flex-direction: row;
    }
  
    .project-details {
      width: 50%;
    }
  
    .project-image {
      width: 50%;
    }
  }
 */
