#contact {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    height: 80svh;
  }
  
  #contact form {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  #contact label {
    margin-bottom: 5px;
    color: var(--text); /* Your preferred color */
    transition: all 1s ease-in-out;
  }
  
  #contact input[type="text"],
  #contact input[type="email"],
  #contact textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  #contact textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  #contact input[type="submit"] {
    padding: 10px 20px;
    background-color: black; /* Your preferred color */
    color: #fff;
    border: solid .5px rgba(255, 255, 255, 0.493);
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  #contact input[type="submit"]:hover {
    background-color: rgb(68, 67, 67); /* Your preferred hover color */
  }
  
  #contact p {
    margin-top: 10px;
    color: green; /* Color for success message */
    font-weight: bold;
  }
  

  /* Media Queries */
@media (min-width: 600px) {
  #contact form {
    width: 80%; /* Adjust form width for larger screens */
  }
}

@media (min-width: 768px) {
  #contact form {
    width: 70%; /* Adjust form width for even larger screens */
  }
}

@media (min-width: 1024px) {
  #contact form {
    width: 60%; /* Adjust form width for large screens */
  }
}