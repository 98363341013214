html {
    scroll-behavior: smooth;
    transition: scroll-behaviour 0.3s ease;
    background-color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .App {
    display: flex;
    height: 100vh;
    position: relative;
  }

  .content-container {
    width: 80%;
    height: max-content;
    background-color:  var(--bg);
    color: var(--text);
    padding-left: 30%;
    padding-right: 10%;
    width: 70%;
    transition: all 1s ease-in-out; 
  }
  
  main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left:10px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

 

  .light {
    --bg: white;
    --text: black;
    --border: solid .5px rgba(0, 0, 0, 0.2);
    --boxshadow: 0 12px 24px rgba(0, 0, 0, 0.2); 
    --icon-fill: invert(0%) sepia(97%) saturate(9%) hue-rotate(282deg) brightness(103%) contrast(98%);
  }
  
  .dark {
    --bg: black;
    --text: white;
    --border: solid .5px rgba(255, 255, 255, 0.2);
    --boxshadow: 0 12px 24px rgba(255, 255, 255, 0.2); 
    --icon-fill: invert(100%) sepia(0%) saturate(0%) hue-rotate(276deg) brightness(103%) contrast(102%);
  }

  /* @media (min-width: 600px) {
    .content-container {
      padding-left: 30%;
      padding-right: 10%;
      width: 70%;
    }
  }
  
  @media (min-width: 768px) {
    .App {
      flex-direction: row;
    }
  
    .content-container {
      padding-left: 20%;
      padding-right: 20%;
    }
  }
  
  @media (min-width: 1024px) {
    .content-container {
      padding-left: 25%;
      padding-right: 25%;
    }
  } */