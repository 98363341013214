#skills {
    display: flex;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
    margin:  30px;
  }
  
  #skills ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center;
  }
  
  #skills li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    perspective: 1000px; 
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease;
  }
  
  #skills .icon {
    height: 80px;
    margin-bottom: 8px;
    transition: transform 0.5s;
    animation: float 5s ease-in-out infinite;
  }
  
  #skills span {
    text-align: center;
    font-size: 16px;
    color: white;
  }

  #skills li:hover {
    transform: scale(1.05) translateY(-5px);
  }

  #skills .filter-icon {
    width: 120px;
    filter: var(--icon-fill);
    }
  

    @keyframes float {
        0% {
          transform: translateZ(0px) rotateX(0deg) rotateY(0deg);
        }
        25% {
          transform: translateZ(20px) rotateX(10deg) rotateY(10deg);
        }
        50% {
          transform: translateZ(0px) rotateX(0deg) rotateY(0deg);
        }
        75% {
          transform: translateZ(-20px) rotateX(-10deg) rotateY(-10deg);
        }
        100% {
          transform: translateZ(0px) rotateX(0deg) rotateY(0deg);
        }
      }