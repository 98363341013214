#about {
  position: relative;
  margin-top: 30vh;
  margin-bottom: 25%;
}

h1 {
  font-size: 48px;
}

#about span {
  font-size: 20px;
  animation: colorChange 10s infinite;
}

.additional-details {
  display: flex;
  flex-direction: row;
}

#education {
  border: var(--border);
  border-radius: 20px;
  list-style: none;
  width: 50%;
  padding: 10px;
  margin: 5px;
}

#education li{
  list-style: none;
  margin: 0;
  padding: 0;
}

#education ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 1200px) {
  #about {
    margin-top: 25vh;
    margin-bottom: 20%;
  }
  
  h1 {
    font-size: 40px;
  }
  
  #about span {
    font-size: 18px;
  }
  
}

@media (max-width: 992px) {
  #about {
    margin-top: 20vh;
    margin-bottom: 15%;
  }
  
  h1 {
    font-size: 36px;
  }
  
  #about span {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  #about {
    margin-top: 15vh;
    margin-bottom: 10%;
  }
  
  h1 {
    font-size: 32px;
  }
  
  #about span {
    font-size: 14px;
  }
}

/* @media (max-width: 576px) {
  #about {
    margin-top: 10vh;
    margin-bottom: 5%;
  }
  
  h1 {
    font-size: 28px;
  }
  
  #about span {
    font-size: 12px;
  }
} */
