.toggle-switch {
    position: fixed;
    top: 5vh;
    right: 5vh;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    background-color: rgba(175, 170, 170, 0.25);
    border-radius: 2rem;
    transition: all 0.3s linear;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .toggle-switch .switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(175, 170, 170, 0.25);
    border-radius: 2rem;
    transition: all 0.3s linear;
  }
  
  .toggle-switch .switch::after {
    content: "";
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: black;
    transition: all 0.3s linear;
  }
  
  .toggle-switch input:checked + .switch {
    background-color: rgba(175, 170, 170, 0.25);
  }
  
  .toggle-switch input:checked + .switch::after {
    transform: translateX(1.5rem);
    background-color: black;
  }
  