.statistics{
    float: right;
    width: 50%;
    height: 20vh;
    display: flex;
    flex-direction: row;
    border: var(--border);
    border-radius: 20px;
    margin: 5px;
}

.total{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border: var(--border);
    border-radius: 50%;
}

.difficulty{
    float: right;
    display:flex;
    flex-direction: column;
    margin: 10px;
    width: 50%;
    justify-content: space-between;
}

.solved{
    font-size: 13px;
}


.progress-bar-container {
    width: 115%;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
  }

.easy .progress-bar-container{
    border: solid 1px #1cbaba;
}

.medium .progress-bar-container{
    border: solid 1px #ffb700;
}

.hard .progress-bar-container{
    border: solid 1px #f63737;
}
  
  
  .progress-bar {
    height: 14px;
    width: 0%;
    transition: width 0.5s ease;
  }
  
  .progress-label {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .easy .progress-bar {
    background-color: #1cbaba;
  }

  .medium .progress-bar {
    background-color: #ffb700;
  }

  .hard .progress-bar {
    background-color: #f63737;
  }

  .total {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
}

.total .title {
    margin-bottom: 5px;
    font-weight: bold;
}


  
/* Media Queries */
@media (min-width: 600px) {
  .statistics {
    width: 100%;
    flex-direction: column;
  }

  .total {
    width: 40%;
  }

  .difficulty {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .statistics {
    flex-direction: row;
    width: 100%;
  }

  .total {
    width: 30%;
  }

  .difficulty {
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .statistics {
    width: 50%;
  }

  .total {
    width: 30%;
  }

  .difficulty {
    width: 50%;
  }
}